.App {
  text-align: center;
  background-color: #ffffff;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  font-family: 'Audrey';
  font-weight: bold;
  color: rgb(0, 0, 0);
}

.App-link {
  color: #61dafb;
}

.Sub-heading {
  font-family: 'Audrey';
  font-style: italic;
  font-weight: 500;
  font-size: 32px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
