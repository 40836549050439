
.About-me {
    padding-top: 15%;
}

.About-section {
    padding-left: 25%;
    padding-right: 25%;
}

.About-text {
    font-family: 'Audrey';
    font-weight: 200;
    font-size: 23px;
}
