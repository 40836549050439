
.Top-bar {
    display: flex;
    flex-direction: row;
    width: 98%;
    padding-top: 1%;
    justify-content: space-evenly;
}

.Title {
    font-family: 'Audrey';
    font-weight: bold;
    font-size: 42px;
    width: 100%;
    padding-right: 40px;
}
.Arrow {
    height: 40px;
    padding-top: 3%;
    align-items: center;
    rotate: -90deg;
}

.Rotated {
    transform: rotate(90deg); /* Equal to rotateZ(45deg) */
}