.Section {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
    width: calc(90%);
    align-items: center;
    row-gap: 30px;
    /*padding-left: 10%;*/

}

