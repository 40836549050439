.Group {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    column-gap: 10px;
}

.Text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    padding-left: 70px;
}

.ProjectName {
    font-family: 'Audrey';
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    margin-bottom: 0px;
}

.Tag {
    display: flex;
    font-family: 'Audrey';
    font-weight: medium;
    font-size: 12px;
    text-align: center;
    background-color: #D9D9D9;
    padding-inline: 10px;
    padding-block: 4px;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    width: fit-content;
}

.Tags {
    display: flex;
    flex-direction: row;
    column-gap: 6px;
    margin-block: 0px;
}

.Description {
    display: flex;
    font-family: 'Audrey';
    font-weight: normal;
    font-size: 16px;
    width: 100%;
    text-align: left;
    margin-top: 0px;
}

.Images {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    row-gap: 10px;
    width: 60%;
    height: 30%;
    height: fit-content;
    /*background-color: blue;*/
}

.Horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 50%;
}

.Image {
    margin: 10px;
    box-shadow: black;
    border-radius: 18px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));;
}

.ImageWrapper {
    display: flex;
    flex-direction: row;
    width: 50%;
    height: 100%;
    justify-content: center;
    align-items: center;
    /*background-color: violet;*/
}