.Projects-header {
    display: flex;
    text-align: center;
    justify-content: center;
    color: white;
    background-color: black;
    padding-inline: 35px;
    padding-block: 18px;
    border-radius: 17px;
    width: 13%;
}